/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/material';
import type { LocalizationMap } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { type ReactElement } from 'react';
import type { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useLocaleUrl } from '../../../../file-hooks/use-locale-url';
import { PreviewError } from '../../error';
import it_IT from '@react-pdf-viewer/locales/lib/it_IT.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export function PreviewPdf(props: {
    fileLink: string;
    nHeight: string;
  }) {
  const filePath = props.fileLink;

  const { url, error } = useLocaleUrl(filePath);

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <Box style={{ padding: '0px 2px', marginLeft: '40px' }}>
              <ShowSearchPopover />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <Zoom />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </Box>
            <Box style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </Box>
            <Box style={{ padding: '0px 2px', display: 'flex', alignItems: 'center' }}>
              <CurrentPageInput /> / <NumberOfPages />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </Box>
            <Box style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <EnterFullScreen />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <Download />
            </Box>
            <Box style={{ padding: '0px 2px', marginRight: '30px' }}>
              <Print />
            </Box>
          </Box>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <>
      <PreviewError error={error} />
      {url && (
        <Box
          style={{
            height: props.nHeight,
          }}
        >
          <Viewer
            fileUrl={url}
            localization={it_IT as unknown as LocalizationMap}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Box>
      )}
    </>
  );
}
