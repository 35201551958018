/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/iframe-has-title */
import { CloseTwoTone } from '@mui/icons-material';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useLocation } from 'react-router';

import useToggleState from '../../hooks/useToggleState';

import styles from './styles.module.css';

export function ChatAI() {
  const [open, toggle] = useToggleState(false);
  const handleOpen = useCallback(() => {
    toggle();
  }, [toggle]);
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const idFascicolo = parseInt(locationArray[2]);
  if (locationArray[1] !== 'fascicolo' || isNaN(idFascicolo)) {
    if (open) toggle();

    return null;
  }

  return (
    <>
      <IconButton
        className={styles.IconAI}
        onClick={handleOpen}
      >
        <Tooltip placement="top" title="SiriA" followCursor>
          <AutoAwesomeTwoToneIcon sx={{
            color: '#fff',
            width: '0.9em',
            height: '0.9em',
          }}
          />
        </Tooltip>
      </IconButton>
      <Box className={`${styles.ChatAI} ${open ? styles.openChat : ''}`}>
        <Box className={styles.headerChat}>
          <Typography p={2} variant="h5">
            <AutoAwesomeTwoToneIcon sx={{
              width: '0.7em',
              height: '0.7em',
              marginRight: '5px',
            }}
            />
            SiriA
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseTwoTone />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ height: '100%' }}>
          <iframe frameBorder="0" height="100%" src={`https://ia.ceore.it/?fascicolo_id=${idFascicolo}`} width="100%" />
        </Box>
      </Box>
    </>
  );
}
